<template>
    <div class="card branch">
        <div class="card-image">
            <GmapMap class="map"
                     v-bind:center="branch.coordinate"
                     v-bind:zoom="17"
                     v-bind:options="mapOptions">
                <GmapMarker v-bind:position="branch.coordinate"
                            v-bind:clickable="true"
                            v-bind:draggable="false">
                </GmapMarker>
            </GmapMap>
        </div>
        <div class="card-content">
            <div class="is-flex is-justify-content-flex-end"
                 style="margin-bottom: 2rem; ">
                <div class="tabs is-small is-toggle">
                    <ul>
                        <li v-bind:class="{ 'is-active': lang === 'en' }"
                            v-on:click="lang = 'en'">
                            <a>English</a>
                        </li>
                        <li v-bind:class="{ 'is-active': lang === 'zh-hk' }"
                            v-on:click="lang = 'zh-hk'">
                            <a>繁體中文</a>
                        </li>
                        <li v-bind:class="{ 'is-active': lang === 'zh-cn' }"
                            v-on:click="lang = 'zh-cn'">
                            <a>簡體中文</a>
                        </li>
                    </ul>
                </div>
            </div>
            <h1 class="title has-text-centered has-text-weight-bold"
               v-if="branch.name">
                <strong>{{ branch.name[lang] }}</strong>
            </h1>
            <h1 class="title has-text-centered has-text-weight-bold"
               v-if="!branch.name">
                <strong>{{ locationTypeMap[branch.type][lang] }}</strong>
            </h1>
            <div class="media">
                <div class="media-left">
                    <strong>{{ branch.region }}</strong>
                </div>
                <div class="media-content">
                    <p class="subtitle is-6">
                        {{ branch.address[lang] }}
                    </p>
                </div>
            </div>
            <div class="media">
                <div class="media-left">
                    <strong>Phone:</strong>
                </div>
                <div class="media-content">
                    {{ branch.phone }}
                </div>
            </div>
            <div class="media">
                <div class="media-left">
                    <strong>From:</strong>
                </div>
                <div class="media-content">
                    {{ branch.openHours.from | dateFnsFormatter("HH:mm") }}
                </div>
            </div>
            <div class="media" style="margin-bottom: 1rem; ">
                <div class="media-left">
                    <strong>To:</strong>
                </div>
                <div class="media-content">
                    {{ branch.openHours.to | dateFnsFormatter("HH:mm") }}
                </div>
            </div>

            <div class="media">
                <div class="media-content">
                    <div class="content">
                        <nav class="level">
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">Daily Sales</p>
                                    <p class="title">
                                        HK$38,000
                                    </p>
                                </div>
                            </div>
                            <div class="level-item has-text-centered">
                                <div>
                                    <p class="heading">Monthly Sales</p>
                                    <p class="title">
                                        HK$763,000
                                    </p>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            <footer class="card-footer">
                <a class="card-footer-item"
                   v-on:click="isBranchEmployeesModalActive = true">
                    <font-awesome-icon icon="people" style="margin-right: 10px"></font-awesome-icon>
                    <span>Employees</span>
                </a>
                <a class="card-footer-item has-text-warning"
                   v-on:click="isBranchEditModalActive = true">
                    <font-awesome-icon icon="edit" style="margin-right: 10px"></font-awesome-icon>
                    <span>Edit</span>
                </a>
                <a class="card-footer-item has-text-danger"
                   v-on:click="openDeleteModal">
                    <font-awesome-icon icon="trash-alt" style="margin-right: 10px"></font-awesome-icon>
                    <span>Delete</span>
                </a>
            </footer>
        </div>

        <modal-branch mode="edit"
                      v-bind:branch="branch"
                      v-model="isBranchEditModalActive">
        </modal-branch>
        <modal-branch-employees v-model="isBranchEmployeesModalActive"></modal-branch-employees>
        <b-modal v-bind:active.sync="isBranchDeleteModalActive" has-modal-card>
            <modal-branch-delete v-bind:branch="branch"></modal-branch-delete>
        </b-modal>
    </div>
</template>

<script>
    import ModalBranch from "../modals/ModalBranch";
    import ModalBranchDelete from "../modals/ModalBranchDelete";
    import ModalBranchEmployees from "@/components/Branches/ModalBranchEmployees.vue";

    export default {
        name: "BranchCard",
        components: {
            ModalBranchEmployees,
            ModalBranchDelete,
            ModalBranch,
        },
        props: {
            branch: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                mapOptions: {
                    disableDefaultUI: true,
                },

                locationTypeMap: {
                    'salon': {
                        'zh-hk': 'Marier美容中心',
                        'zh-cn': 'Marier美容中心',
                        'en': 'Marier Salon'
                    },
                    'counter': {
                        'zh-hk': 'Marier專門店/專櫃',
                        'zh-cn': 'Marier专门店/专柜',
                        'en': 'Marier Counter'
                    }
                },

                lang: 'en',

                isBranchEditModalActive: false,
                isBranchEmployeesModalActive: false,
                isBranchDeleteModalActive: false
            }
        },
        methods: {
            openDeleteModal() {
                this.isBranchDeleteModalActive = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .branch {
        display: flex;

        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 2rem;

        & > .card-image {
            min-width: 320px;
            flex: 1;

            & > .map {
                width: 100%;
                height: 100%;
            }
        }

        & > .card-content {
            position: relative;
            flex: 2;
            padding-bottom: 48px;

            & > .tabs {
                & > ul {
                    display: flex;
                    justify-content: center;
                }
            }

            .media-left {
                min-width: 52px;
                text-align: right;
            }

            & > .card-footer {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
</style>
