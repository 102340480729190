<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Reorder Branches</p>
        </header>
        <section class="modal-card-body">
            <article class="message">
                <div class="message-body">
                    You can reorder branches here. The order will be reflected in the main web pages.
                </div>
            </article>

            <div class="tabs is-small is-toggle is-toggle-rounded">
                <ul>
                    <li v-bind:class="{ 'is-active': lang === 'en' }"
                        v-on:click="lang = 'en'">
                        <a>English</a>
                    </li>
                    <li v-bind:class="{ 'is-active': lang === 'zh-hk' }"
                        v-on:click="lang = 'zh-hk'">
                        <a>繁體中文</a>
                    </li>
                    <li v-bind:class="{ 'is-active': lang === 'zh-cn' }"
                        v-on:click="lang = 'zh-cn'">
                        <a>簡體中文</a>
                    </li>
                </ul>
            </div>

            <nav class="panel">
                <div class="panel-heading">
                    <div class="region">
                        <strong>Region</strong>
                    </div>
                    <div class="type">
                        <strong>Type</strong>
                    </div>
                    <div class="address">
                        <strong>Address</strong>
                    </div>
                </div>
                <draggable v-model="branches"
                           v-on:end="onDragEnd">
                    <transition-group>
                        <a class="panel-block is-active"
                           v-for="branch in branches"
                           v-bind:key="branch._id">
                            <div class="region">
                                <strong>
                                    {{ branch.region }}
                                </strong>
                            </div>
                            <div class="type">
                                {{ locationTypeMap[branch.type][lang] }}
                            </div>
                            <div class="address">
                                {{ branch.address[lang] }}
                            </div>
                        </a>
                    </transition-group>
                </draggable>
            </nav>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-primary"
                    v-bind:disabled="!isEdited"
                    v-bind:class="{ 'is-loading': isLoading }"
                    v-on:click="save">
                Save
            </button>
        </footer>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "ModalBranchReorder",
    components: {
        draggable,
    },
    props: {

    },
    data () {
        return {
            isLoading: false,
            isEdited: false,

            lang: "en",
            branches: [],

            "sortableOptions": {
                "chosenClass": "is-selected",
            },
            "locationTypeMap": {
                "salon": {
                    "zh-hk": "Marier美容中心",
                    "zh-cn": "Marier美容中心",
                    "en": "Marier Salon"
                },
                "counter": {
                    "zh-hk": "Marier專門店/專櫃",
                    "zh-cn": "Marier专门店/专柜",
                    "en": "Marier Counter"
                }
            },
        }
    },
    computed: {},
    methods: {
        async save() {
            if (this.isEdited) {
                this.isLoading = true;
                try {
                    await this.$store.dispatch(
                        "shop/updateShop",
                        {
                            "branches": this.branches
                                .map(
                                    ({ _id }) => _id
                                ),
                        }
                    );
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                this.$parent.close();
            }
        },

        onDragEnd() {
            this.isEdited = true;
        }
    },
    mounted() {
        this.branches = this.$store.getters["shop/branches"];
    }
}
</script>

<style lang="scss" scoped>
.modal-card-foot {
    justify-content: flex-end;
}

.tabs {
    & > ul {
        display: flex;
        justify-content: center;
    }
}

.panel-heading {
    font-size: 16px;
}

.panel-heading,
.panel-block {
    display: flex;
    justify-content: stretch;

    & > .region {
        flex: 1;
        text-align: center;
        margin-right: 5px;
    }

    & > .type {
        flex: 1;
        text-align: center;
        margin-right: 5px;
    }

    & > .address {
        flex: 5;
        text-align: left;
    }
}
</style>
