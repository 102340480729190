<template>
    <b-modal has-modal-card
             v-bind:active.sync="isModalActive">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ mode | capitalize }} a Branch
                </p>
            </header>

            <section class="modal-card-body">
                <div class="field">
                    <label class="label">Branch Type (*required)</label>
                    <b-select placeholder=""
                              v-model="type">
                        <option value="salon">Salon (美容中心)</option>
                        <option value="counter">Counter (專門店/專櫃)</option>
                    </b-select>
                </div>

                <div class="field">
                    <label class="label">Branch opening period</label>
                    <p style="margin-bottom: .5em;">
                        Opening period for a branch is <i>optional</i>. You can decide whether to fill opening date or
                        closing date or none.
                    </p>
                    <b-field label="Opening Date">
                        <b-field>
                            <b-datepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="openingDate">
                            </b-datepicker>
                            <div class="control">
                                <a class="button"
                                   v-on:click="openingDate = null">
                                    Cancel
                                </a>
                            </div>
                        </b-field>
                    </b-field>
                    <b-field label="Closing Date">
                        <b-field>
                            <b-datepicker
                                placeholder="Click to select..."
                                icon="calendar-today"
                                v-model="closingDate">
                            </b-datepicker>
                            <div class="control">
                                <a class="button"
                                   v-on:click="closingDate = null">
                                    Cancel
                                </a>
                            </div>
                        </b-field>
                    </b-field>
                </div>

                <div class="field">
                    <label class="label">Branch Name</label>
                    <p style="margin-bottom: .5em;">
                        Name for a branch is <i>optional</i>. A name will be automatically generated if a name is not
                        explicitly provided.
                    </p>
                    <b-collapse v-bind:open="false">
                        <button class="button is-dark" slot="trigger">
                            <span>Add a custom name</span>
                            <span class="icon is-small">
                            <font-awesome-icon class="icon" icon="angle-down"></font-awesome-icon>
                        </span>
                        </button>
                        <div>
                            <div class="content">
                                <b-field style="margin-top: .75rem;">
                                    <p class="control" style="min-width: 90px; ">
                                        <span class="button is-static" style="width: 100%; ">English</span>
                                    </p>
                                    <b-input type="text"
                                             expanded
                                             placeholder=""
                                             v-model.trim="name['en']">
                                    </b-input>
                                </b-field>
                                <b-field>
                                    <p class="control">
                                        <span class="button is-static">繁體中文</span>
                                    </p>
                                    <b-input type="text"
                                             expanded
                                             placeholder=""
                                             v-model.trim="name['zh-hk']">
                                    </b-input>
                                </b-field>
                                <b-field>
                                    <p class="control">
                                        <span class="button is-static">簡體中文</span>
                                    </p>
                                    <b-input type="text"
                                             expanded
                                             placeholder=""
                                             v-model.trim="name['zh-cn']">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                    </b-collapse>
                </div>

                <div class="field">
                    <label class="label">Branch Address (*required)</label>
                    <b-field>
                        <b-select placeholder="Region"
                                  v-model="region">
                            <option value="HKI">Hong Kong Island</option>
                            <option value="KWN">Kowloon</option>
                            <option value="NT">New Territories</option>
                        </b-select>
                    </b-field>
                    <b-field>
                        <p class="control" style="min-width: 90px; margin-bottom: 0; ">
                            <span class="button is-static" style="width: 100%; ">English</span>
                        </p>
                        <b-input type="text"
                                 expanded
                                 placeholder=""
                                 required
                                 v-model.trim="address['en']">
                        </b-input>
                    </b-field>
                    <b-field>
                        <p class="control" style="margin-bottom: 0; ">
                            <span class="button is-static">繁體中文</span>
                        </p>
                        <b-input type="text"
                                 expanded
                                 placeholder=""
                                 required
                                 v-model.trim="address['zh-hk']">
                        </b-input>
                    </b-field>
                    <b-field>
                        <p class="control" style="margin-bottom: 0; ">
                            <span class="button is-static">簡體中文</span>
                        </p>
                        <b-input type="text"
                                 expanded
                                 placeholder=""
                                 required
                                 v-model.trim="address['zh-cn']">
                        </b-input>
                    </b-field>
                </div>

                <div class="field">
                    <label class="label">Branch Coordinate (*required)</label>
                    <b-field grouped>
                        <b-field label="Latitude" expanded>
                            <b-input type="text"
                                     required
                                     v-model.trim="coordinate.lat">
                            </b-input>
                        </b-field>
                        <b-field label="Longitude" expanded>
                            <b-input type="text"
                                     required
                                     v-model.trim="coordinate.lng">
                            </b-input>
                        </b-field>
                    </b-field>
                    <b-collapse v-bind:open="false">
                        <button class="button is-dark" slot="trigger">
                            <span>Select coordinate from map</span>
                            <span class="icon is-small">
                            <font-awesome-icon class="icon" icon="angle-down"></font-awesome-icon>
                        </span>
                        </button>
                        <div class="coordinate-selector">
                            <div class="content">
                                <GmapMap v-bind:center="mapCenter"
                                         v-bind:zoom="mapZoom"
                                         v-bind:options="mapOptions"
                                         v-on:center_changed="getCoordinateFromMap"
                                         style="width: 500px; height: 400px">
                                    <div slot="visible">
                                        <div class="crosshair-wrapper">
                                            <font-awesome-icon class="crosshair"
                                                               v-bind:icon="['fal', 'plus']">
                                            </font-awesome-icon>
                                        </div>
                                    </div>
                                </GmapMap>
                            </div>
                        </div>
                    </b-collapse>
                </div>

                <div class="field">
                    <label class="label">Phone (*required)</label>
                    <b-input type="tel"
                             expanded
                             has-counter
                             required
                             minlength="8"
                             maxlength="8"
                             v-model="phone">
                    </b-input>
                </div>

                <div class="field">
                    <label class="label">Opening Hours (*required)</label>
                    <b-field grouped>
                        <b-field label="From" expanded>
                            <b-input type="time"
                                     required
                                     v-model="openHours.from">
                            </b-input>
                        </b-field>
                        <b-field label="To" expanded>
                            <b-input type="time"
                                     required
                                     v-model="openHours.to">
                            </b-input>
                        </b-field>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button"
                        v-on:click="$parent.close()">
                    Cancel
                </button>
                <button class="button is-primary"
                        v-bind:disabled="!allowSave"
                        v-bind:class="{ 'is-loading': loading }"
                        v-on:click="save">
                    Save
                </button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
import format from "date-fns/format";
import parse from "date-fns/parse";
import BField from "buefy/src/components/field/Field";

export default {
    name: "ModalBranch",
    components: {
        BField
    },
    props: {
        "value": {
            type: Boolean,
        },
        "mode": {
            type: String,
            required: true,
            validator: (val) => {
                return ["add", "edit"].includes(val)
            }
        },
        "branch": {
            type: Object
        },
    },
    data () {
        return {
            loading: false,

            mapCenter: {
                lat: 22.384149,
                lng: 114.1313199
            },
            mapZoom: 11,
            mapOptions: {
                // disableDefaultUI: true
            },

            searchMapInput: "",

            type: "",
            name: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            openingDate: null,
            closingDate: null,
            region: "",
            address: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            coordinate: {
                lat: 22.384149,
                lng: 114.1313199
            },
            phone: "",
            openHours: {
                from: null,
                to: null
            }
        }
    },
    computed: {
        allowSave() {
            return this.type &&
                this.region &&
                this.address["en"] &&
                this.address["zh-hk"] &&
                this.address["zh-cn"] &&
                this.coordinate.lat &&
                this.coordinate.lng &&
                this.phone &&
                this.phone.length === 8 &&
                this.openHours.from &&
                this.openHours.to
        },

        isModalActive: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        getCoordinateFromMap({ lat, lng, }) {
            this.coordinate.lat = lat();
            this.coordinate.lng = lng();
        },

        searchLocation() {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    "address": this.searchMapInput
                },
                (results, status) => {
                    if (status === "OK") {
                        this.currentLocation.lat = results[0].geometry.location.lat();
                        this.currentLocation.lng = results[0].geometry.location.lng();
                    }
                }
            );
        },

        async save() {
            if (this.allowSave) {
                const temp = {
                    type: this.type,
                    region: this.region,
                    address: this.address,
                    coordinate: this.coordinate,
                    phone: this.phone,
                    openHours: {}
                };

                temp.openHours.from = parse(this.openHours.from, "HH:mm", new Date()).toISOString();
                temp.openHours.to = parse(this.openHours.to, "HH:mm", new Date()).toISOString();

                if (this.name.en && this.name["zh-hk"] && this.name["zh-cn"]) {
                    temp.name = this.name;
                }

                if (this.openingDate) {
                    temp.openingDate = this.openingDate;
                }

                if (this.closingDate) {
                    temp.closingDate = this.closingDate;
                }

                this.loading = true

                if (this.mode === "add") {
                    try {
                        await this.$store.dispatch(
                            "shop/addBranch",
                            temp
                        );
                    } catch (e) {
                        this.loading = false;
                        return;
                    }
                }

                if (this.mode === "edit") {
                    temp._id = this.branch._id
                    try {
                        await this.$store.dispatch(
                            "shop/updateBranch",
                            temp
                        );
                    } catch (e) {
                        this.loading = false;
                        return;
                    }
                }

                this.loading = false
                this.$parent.close()
            }
        }
    },
    mounted() {
        if (this.mode === "edit") {
            if (this.branch) {
                this.type = this.branch.type;
                if (this.branch.name) {
                    this.name = this.branch.name;
                }
                if (this.branch.openingDate) {
                    this.openingDate = new Date(this.branch.openingDate);
                }
                if (this.branch.closingDate) {
                    this.closingDate = new Date(this.branch.closingDate);
                }
                this.region = this.branch.region;
                this.address = this.branch.address;
                this.coordinate = this.branch.coordinate;
                this.formattedPhone = `+852 ${this.branch.phone.slice(0, 4)} ${this.branch.phone.slice(4)}`;
                this.phone = this.branch.phone;
                this.openHours.from = format(new Date(this.branch.openHours.from), "HH:mm");
                this.openHours.to = format(new Date(this.branch.openHours.to), "HH:mm");
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-card-foot {
    justify-content: flex-end;
}

.label {
    font-size: 18px;
    margin-top: 1rem;
}

.coordinate-selector {
    & > .content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.crosshair-wrapper {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .crosshair {
        font-size: 42px;
        z-index: 100;
    }
}
</style>
