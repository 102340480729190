<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Delete a Branch</p>
        </header>
        <section class="modal-card-body">
            <div class="content">
                <p class="has-text-centered">
                    This action is <strong>irreversible</strong>. Are you sure you want to delete this branch?
                </p>
            </div>
            <div class="branch-wrapper">
                <div class="card branch">
                    <div class="card-image">
                        <GmapMap class="image is-4by3"
                                 v-bind:center="branch.coordinate"
                                 v-bind:zoom="17"
                                 v-bind:options="mapOptions">
                            <GmapMarker v-bind:position="branch.coordinate"
                                        v-bind:clickable="true"
                                        v-bind:draggable="false">
                            </GmapMarker>
                        </GmapMap>
                    </div>
                    <div class="card-content">
                        <div class="tabs is-small is-toggle is-toggle-rounded">
                            <ul>
                                <li v-bind:class="{ 'is-active': lang === 'en' }"
                                    v-on:click="lang = 'en'">
                                    <a>English</a>
                                </li>
                                <li v-bind:class="{ 'is-active': lang === 'zh-hk' }"
                                    v-on:click="lang = 'zh-hk'">
                                    <a>繁體中文</a>
                                </li>
                                <li v-bind:class="{ 'is-active': lang === 'zh-cn' }"
                                    v-on:click="lang = 'zh-cn'">
                                    <a>簡體中文</a>
                                </li>
                            </ul>
                        </div>
                        <p class="title" v-if="branch.name">
                            {{ branch.name[lang] }}
                        </p>
                        <p class="title" v-if="!branch.name">
                            {{ locationTypeMap[branch.type][lang] }}
                        </p>
                        <div class="media">
                            <div class="media-left">
                                <strong>{{ branch.region }}</strong>
                            </div>
                            <div class="media-content">
                                <p class="subtitle is-6">
                                    {{ branch.address[lang] }}
                                </p>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <strong>Phone:</strong>
                            </div>
                            <div class="media-content">
                                {{ branch.phone }}
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <strong>From:</strong>
                            </div>
                            <div class="media-content">
                                {{ branch.openHours.from | dateFnsFormatter("HH:mm") }}
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <strong>To:</strong>
                            </div>
                            <div class="media-content">
                                {{ branch.openHours.to | dateFnsFormatter("HH:mm") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-danger"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="deleteBranch">
                Delete
            </button>
        </footer>
    </div>
</template>

<script>
import Branch from '../Branches/BranchCard'

export default {
    name: 'ModalBranchDelete',
    components: {Branch},
    props: {
        branch: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            mapOptions: {
                disableDefaultUI: true
            },
            loading: false,

            locationTypeMap: {
                'salon': {
                    'zh-hk': 'Marier美容中心',
                    'zh-cn': 'Marier美容中心',
                    'en': 'Marier Salon'
                },
                'counter': {
                    'zh-hk': 'Marier專門店/專櫃',
                    'zh-cn': 'Marier专门店/专柜',
                    'en': 'Marier Counter'
                }
            },

            lang: 'en'
        }
    },
    methods: {
        async deleteBranch() {
            this.loading = true
            try {
                await this.$store
                    .dispatch(
                        "shop/deleteBranch",
                        this.branch._id
                    );
            } catch (e) {
                this.loading = false
                this.$parent.close()
            }
            this.loading = false
            this.$parent.close()
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-card-body {
    padding-left: 0;
    padding-right: 0;
}

.modal-card-foot {
    justify-content: flex-end;
}

.branch-wrapper {
    padding: 1rem;
    background-color: rgba(245, 245, 245, 1);

    & > .branch {
        width: 320px;
        margin-left: auto;
        margin-right: auto;

        & > .card-content {
            & > .tabs {
                & > ul {
                    display: flex;
                    justify-content: center;
                }
            }

            .media-left {
                min-width: 52px;
                text-align: right;
            }
        }
    }
}
</style>
