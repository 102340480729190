<template>
    <b-modal has-modal-card
             v-bind:active.sync="isModalActive">
        <div class="card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Employees at branch
                </p>
            </header>
            <section class="card-content">
                <b-table detailed
                         detail-key="_id"
                         v-bind:data="employees">
                    <template>
                        <b-table-column v-slot="{ row }"
                                        field="id"
                                        label="Joined on"
                                        centered>
                            {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        field="name"
                                        label="First Name"
                                        sortable>
                            {{ row.name }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        field="surname"
                                        label="Last Name"
                                        sortable>
                            {{ row.surname }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        field="phone"
                                        label="Phone"
                                        centered
                                        sortable>
                            {{ row.phone }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        field="title"
                                        label="Title"
                                        sortable>
                            {{ row.title }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        label="Type"
                                        centered
                                        sortable>
                            {{ row.type }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        label="Wage"
                                        centered >
                            {{ row.wage }}
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        label="Status"
                                        centered>
                        <span class="tag is-success"
                              v-if="row.isEmployed">
                            Employed
                        </span>
                            <span class="tag is-warning"
                                  v-if="!row.isEmployed">
                            Quited
                        </span>
                        </b-table-column>
                        <b-table-column v-slot="{ row }"
                                        label=""
                                        centered>
                            <router-link class="button is-small is-text"
                                         v-bind:to="{ name: 'user-details', params: { id: row._id } }">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                            </router-link>
                            <button class="button is-small is-text is-danger"
                                    v-bind:class="{ 'is-loading': isDeleting }"
                                    v-on:click="reauthenticateDelete(row)">
                                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                            </button>
                        </b-table-column>
                    </template>

                    <template v-slot:detail="{ row }">
                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <nav class="level">
                                        <div class="level-item has-text-centered">
                                            <div>
                                                <p class="heading">Prepayment</p>
                                                <p class="title">
                                                    HK$0
                                                </p>
                                            </div>
                                        </div>
                                        <div class="level-item has-text-centered">
                                            <div>
                                                <p class="heading">Spending</p>
                                                <p class="title">
                                                    HK${{ calculateTotalOrderAmount(orders.filter(({ owner }) => owner === row._id)) + calculateTotalBookingAmount(bookings.filter(({ owner }) => owner === row._id)) }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="level-item has-text-centered">
                                            <div>
                                                <p class="heading">Orders</p>
                                                <p class="title">
                                                    {{ orders.filter(({ owner }) => owner === row._id).length }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="level-item has-text-centered">
                                            <div>
                                                <p class="heading">Bookings</p>
                                                <p class="title">
                                                    {{ bookings.filter(({ owner }) => owner === row._id).length }}
                                                </p>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </article>
                    </template>
                </b-table>
            </section>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "ModalBranchEmployees",
    props: {
        "value": {
            type: Boolean,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        isModalActive: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },
        employees () {
            return [
                {
                    _id: "f6c68820-7cf2-4781-8540-d58bb38f92c3",
                    name: "Test",
                    surname: "Monthly",
                    phone: "12345678",
                    title: "Beautician",
                    type: "Full-time",
                    wage: "HK$17,500/Month",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 25),
                    isEmployed: true,
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Hourly",
                    phone: "12345678",
                    title: "Part-time Beautician",
                    type: "Part-time",
                    wage: "HK$75/Hour",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: false,
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Monthly 2",
                    phone: "12345678",
                    title: "Beautician",
                    type: "Full-time",
                    wage: "HK$17,500/Month",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: true,
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Hourly",
                    phone: "12345678",
                    title: "Promoter",
                    type: "Part-time",
                    wage: "HK$55/Hour",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: true,
                },
            ];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
