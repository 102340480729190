<template>
    <section class="view shop-branches">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        MARIER SKINCARE currently has {{ branches.filter(branch => branch.type === "salon").length }}
                        salon(s) and {{ branches.filter(branch => branch.type === "counter").length }} counter(s).
                    </h1>
                    <!-- <h2 class="subtitle">
                        subtitle
                    </h2> -->
                </div>
            </div>
        </section>
        <tool-bar>
            Branches
            <template slot="buttons">
                <div class="level-item" v-show="branches.length > 0">
                    <button class="button"
                            v-on:click="openReorderModal()">
                        <span class="icon is-small">
                            <font-awesome-icon v-bind:icon="['far', 'arrows']"></font-awesome-icon>
                        </span>
                        <span>Reorder</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-on:click="openModal('add')">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a branch</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="branch-wrapper">
            <branch-card v-for="branch in branches"
                         v-bind:key="branch._id"
                         v-bind:branch="branch">
            </branch-card>
        </div>

        <modal-branch v-bind:mode="branchModalMode"
                      v-model="isBranchModalActive">
        </modal-branch>

        <b-modal v-bind:active.sync="isReorderModalActive" has-modal-card>
            <modal-branch-reorder></modal-branch-reorder>
        </b-modal>
    </section>
</template>

<script>
import ModalBranch from "../components/modals/ModalBranch";
import ModalBranchReorder from "../components/modals/ModalBranchReorder";
import ToolBar from "../components/layouts/ToolBar";
import BranchCard from "../components/Branches/BranchCard";

export default {
    name: "ShopBranches",
    components: {
        BranchCard,
        ToolBar,
        ModalBranchReorder,
        ModalBranch,
    },
    data() {
        return {
            isReorderModalActive: false,
            isBranchModalActive: false,
            branchModalMode: "add",

            edited: false
        }
    },
    computed: {
        branches() {
            return this.$store.state.shop.branches;
        },
    },
    methods: {
        openModal(mode) {
            this.isBranchModalActive = true;
            this.branchModalMode = mode;
        },

        openReorderModal() {
            this.isReorderModalActive = true;
        },
    }
}
</script>

<style lang="scss" scoped>
.shop-branches {
    position: relative;
    background-color: rgba(245, 245, 245, 1);

    .hero {
        background-image: url("../assets/hero_background_4.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;

        & > .hero-body {
            & > .container {
                width: 100%;
            }
        }
    }

    .branch-wrapper {
        margin-top: 1rem;
        padding-bottom: 1rem;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
</style>
